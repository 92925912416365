import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/auth.js'), 'auth.js')
  resolveStoreModules(require('../store/banks.js'), 'banks.js')
  resolveStoreModules(require('../store/base-settings.js'), 'base-settings.js')
  resolveStoreModules(require('../store/detail.js'), 'detail.js')
  resolveStoreModules(require('../store/games.js'), 'games.js')
  resolveStoreModules(require('../store/player.js'), 'player.js')
  resolveStoreModules(require('../store/promotion.js'), 'promotion.js')
  resolveStoreModules(require('../store/settings.js'), 'settings.js')
  resolveStoreModules(require('../store/sockets.js'), 'sockets.js')
  resolveStoreModules(require('../store/cms/index.js'), 'cms/index.js')
  resolveStoreModules(require('../store/cms/menus.js'), 'cms/menus.js')
  resolveStoreModules(require('../store/themes/agent-floating-icon.js'), 'themes/agent-floating-icon.js')
  resolveStoreModules(require('../store/themes/company-bank.js'), 'themes/company-bank.js')
  resolveStoreModules(require('../store/themes/deposit.js'), 'themes/deposit.js')
  resolveStoreModules(require('../store/themes/game.js'), 'themes/game.js')
  resolveStoreModules(require('../store/themes/language-string.js'), 'themes/language-string.js')
  resolveStoreModules(require('../store/themes/payment.js'), 'themes/payment.js')
  resolveStoreModules(require('../store/themes/player-bank.js'), 'themes/player-bank.js')
  resolveStoreModules(require('../store/themes/player.js'), 'themes/player.js')
  resolveStoreModules(require('../store/themes/promotion.js'), 'themes/promotion.js')
  resolveStoreModules(require('../store/themes/refer-friend.js'), 'themes/refer-friend.js')
  resolveStoreModules(require('../store/themes/settings.js'), 'themes/settings.js')
  resolveStoreModules(require('../store/themes/transaction.js'), 'themes/transaction.js')
  resolveStoreModules(require('../store/themes/withdraw.js'), 'themes/withdraw.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
